<template>
  <el-dialog
    :append-to-body="true"
    v-if="dialog"
    :visible.sync="dialog"
    :title="isAdd ? '新增': '编辑'"
    width="600px"
  >
    <el-form
      ref="form"
      :model="form"
      size="small"
      label-position="labelPosition"
    >
      <el-form-item label="连接类型" prop="connectionType" style="width: 300px;" >
        <el-radio disabled v-model="form.connectionType" label="1">manager</el-radio>
        <el-radio disabled v-model="form.connectionType" label="2">client</el-radio>
      </el-form-item>
      <el-form-item  label="manager账号">
        <el-input v-model="form.account" />
      </el-form-item>
      <el-form-item  label="manager密码">
        <el-input v-model="form.password" />
      </el-form-item>
      <el-form-item  label="服务器地址">
        <el-input v-model="form.serverHost" />
      </el-form-item>
      <el-form-item  label="从服务器地址">
        <el-input v-model="form.serverHostSlave" />
      </el-form-item>
      <el-form-item  label="服务器代码">
        <el-input v-model="form.serverCode" />
      </el-form-item>
      <el-form-item  label="服务器名">
        <el-input v-model="form.serverName" />
      </el-form-item>
      <el-form-item  label="配置文件路径">
        <el-input v-model="form.filePath" />
      </el-form-item>
      <el-form-item  label="服务器时区">
        <el-input v-model="form.timeZone" />
      </el-form-item>
      <el-form-item label="是否为demo" style="width: 300px;" >
        <el-radio v-model="form.isDemo" label="0">是</el-radio>
        <el-radio v-model="form.isDemo" label="1">否</el-radio>
      </el-form-item>
      <el-form-item label="状态" style="width: 300px;" >
        <el-radio v-model="form.status" label="0">无效</el-radio>
        <el-radio v-model="form.status" label="1">有效</el-radio>
        <el-radio v-model="form.status" label="2">未加载</el-radio>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addServer, updateServer } from '@/api/system/apiHotload'
export default {
  props: {
    formData: {
      type: Object,
      default: null
    },
    isAdd:{

    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        connectionType:'2',
        account: '',
        password: '',
        serverHost: '',
        serverHostSlave: '',
        serverCode: '',
        serverName: '',
        filePath: '',
        timeZone: '',
        isDemo: '0',
        status: '0'
      },
      labelPosition: 'left'
    }
  },
  watch:{
    isAdd(newVal,oldVal){
      if(newVal){
        this.clearForm();
        this.form.connectionType = '2'
      }
    },
    formData: {
      handler(newValue, oldValue) {
        if(this.isAdd){
          this.clearForm();
        }else {
          if(this.formData){
            this.edit();
          }
        }
      },
      deep: true
    }
  },
  created(){
    if(this.isAdd){

      this.clearForm();
      this.form.connectionType = '2'
    }else {
      if(this.formData){
        this.edit();
      }
    }
  },
  methods: {
    edit(){
      this.form = {...this.formData};
      if(this.isAdd == false){
        this.form.id = this.formData.id;
      }
      this.form.isDemo = this.form.isDemo + ''
      this.form.status = this.form.status + ''
      this.form.connectionType = this.form.connectionType + ''
    },
    cancel() {
      this.resetForm()
    },
    doSubmit() {
      this.loading = true
      if (this.isAdd) {
        this.doAdd()
      } else this.doEdit()
    },
    doAdd() {

      addServer(this.turnData(this.form)).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.init()
      }).catch(err => {
        this.loading = false
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields();
    },
    doEdit() {
      updateServer(this.turnData(this.form)
      ).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.init()
      }).catch(err => {
        this.loading = false
      })
    },
    turnData(form){
      let params = {
        connectionType: parseInt(form.connectionType),
        account: parseInt(form.account),
        password: form.password,
        serverHost: form.serverHost,
        serverHostSlave: form.serverHostSlave,
        serverCode: form.serverCode,
        serverName: form.serverName,
        filePath: form.filePath,
        timeZone: parseInt(form.timeZone),
        isDemo: parseInt(form.isDemo),
        status: parseInt(form.status)
      }
      if(this.isAdd == false){
        params.id = form.id
      }
      return params;
    },
    clearForm(){
      this.form = {
          connectionType: '1',
          account: '',
          password: '',
          serverHost: '',
          serverHostSlave: '',
          serverCode: '',
          serverName: '',
          filePath: '',
          timeZone: '',
          isDemo: '0',
          status: '0'
      };
    }
  }
}
</script>

<style scoped>
.el-form-item{
  margin-left: 10px;
}
</style>
