<template>
  <div class="app-container">
    <eHeader
      :query="query"
      :sites="sites"
      @add-event="addEvent"
    />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="connectionType" label="连接类型">
        <template slot-scope="scope">
          <span>{{ scope.row.connectionType == 1 ? 'manager' : 'client' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="serverHost" label="服务器地址"/>
      <el-table-column prop="serverCode" label="服务器代码"/>
      <el-table-column prop="filePath" label="配置文件路径"/>
      <el-table-column prop="serverName" label="服务器名"/>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span>{{ getStatus(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
<!--          <edit v-if="checkPermission(['ADMIN','PLATFORM_ALL','PLATFORM_EDIT'])" :data="scope.row" :sup_this="sup_this"/>-->
          <el-button
            v-if="scope.row.status == 2 || scope.row.status == 1"
            size="small"
            type="success"
            @click="loadHandle(scope.row.id)"
          >{{getBtnText(scope.row)}}</el-button>
          <el-button
            size="small"
            type="success"
            @click="editHandle(scope.row)"
          >编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
    <eForm
      ref="form"
      :is-add="idAdd"
      :form-data="formData"
    />
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/system/hotload/header'
  import eForm from '@/components/system/hotload/form'
  import { loadNewServer } from '@/api/system/apiHotload'
  export default {
    name:'hotload',
    components: {
      eHeader,
      eForm
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        sup_this: this,
        sites: [],
        idAdd: false,
        formData: null
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      beforeInit() {
        this.url = 'community/crm/queryServers'
        this.params = { page: this.page, size: this.size }
        const query = this.query;
        const status = query.status;
        if (status !== "" && status !== null) {
          this.params["status"] = status;
        }
        const serverName = query.serverName;
        if (serverName !== "" && serverName !== null) {
          this.params["serverName"] = serverName;
        }
        return true
      },
      getFlag(flag){
        switch (flag) {
          case 0:
            return '成功';
          case 1:
            return '待处理';
          case 2:
            return '处理中';
          case 3:
            return '处理失败';
        }
      },
      loadHandle(id){
        loadNewServer({
          id:id
        }).then((res) => {
          if(res.Status == 0){
            this.init();
            this.$message({
              message: '加载成功',
              type: 'success'
            });
          }else {
            this.$message({
              message: '加载失败',
              type: 'warning',
            });
          }
        })
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '无效';
          case 1:
            return '有效';
          case 2:
            return '未加载';
        }
      },
      editHandle(row){
        this.idAdd = false;
        this.$refs.form.dialog = true
        this.formData = row;
      },
      addEvent(){
        this.idAdd = true;
        this.$refs.form.dialog = true
      },
      getBtnText(row){
        return row.status == 2 ? '加载': '重新加载';
      }
    }
  }
</script>

<style scoped>

</style>
